import { RestService } from "@/app/core/services/core/RestService";
import { Order } from "@/app/core/models/domain/Order";
import { DispatchedToMeFilterContract } from "@/app/core/interfaces/components/dispatched-to-me/DispatchedToMeFilterContract";

export class DispatchedToMeService extends RestService {
  constructor() {
    super("/orders", "orders", "");
  }

  async getDispatchedToMeOrders(params: DispatchedToMeFilterContract): Promise<Order[]> {
    return await this.httpGetAll<Order, DispatchedToMeFilterContract>(
      `${this.apiHref()}`,
      params
    );
  }

  async getDispatchedToMeOrdersCountByStatus(
    params: DispatchedToMeFilterContract
  ): Promise<any> {
    return await this.httpGet(`${this.apiHref()}/order-count-by-status`, params);
  }
}
